import { isBrowser } from "framer-motion";
import mixpanel from "mixpanel-browser";

export default function initMixpanel() {
  if (!isBrowser) {
    return;
  }

  const url = `${window.location.origin}/mp`;

  if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
      api_host: url,
      debug: process.env.NODE_ENV !== "production",
      ignore_dnt: true,
      ip: true,
    });
  }
}
