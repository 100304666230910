"use client";

import initMixpanel from "util/initMixpanel";
import { LoggedOutLiveTrackerProvider } from "shared/user/lives/LoggedOutLiveTrackerContext/LoggedOutLiveTrackerProvider";
import { ContextProvider as AuthProvider } from "../util/useAuth/ContextProvider";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

initMixpanel();

export default function TopLevelProviders({ children }: Props) {
  return (
    <main onTouchStart={noop}>
      <LoggedOutLiveTrackerProvider>
        <AuthProvider>{children}</AuthProvider>
      </LoggedOutLiveTrackerProvider>
    </main>
  );
}

const noop = () => {};
